<!--

  Confirm that the user creates a record within the created period and company.

-->
<template>
  <div class="container">

    <b-modal
      :title="$t('MEMBER.EXIT_CONFIRM_POPUP_TITLE')"
      ref="edit-form"
      hide-footer
      >

      <b-form ref="form" @submit.prevent="on_submit" class="mt-8">
        <b-row>
          <b-col lg="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('MEMBER.DEACTIVATE_AT')">

              <memlist-date-picker
                v-model="item.deactivate_at"
                class="mb-8"
                :placeholder="$t('MEMBER.DEACTIVATE_AT')"
              >
              </memlist-date-picker>
            </b-form-group>

            
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('PERIOD.PERIOD')">

                <b-form-select
                  v-model="item.skv_period_id"
                  :options="period_options"
                  ></b-form-select>

            </b-form-group>
          </b-col>
        </b-row>

        <p>{{ $t('PAGES.FAITH.DEACTIVATE_INFO') }}</p>
        
        <b-row>
          <b-col>
            <RightModalSaveAndCloseButtons
              :text="$t('COMMON.SAVE')"
              :spin="true"
              @clicked="on_submit"
              @close="$refs['edit-form'].hide()"
              ref="saveButton"
            />
          </b-col>
        </b-row>
      </b-form>

    </b-modal>


  </div>

</template>


<script>

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import { mapGetters } from 'vuex';
import store from '@/core/services/store';
import dayjs from 'dayjs';

import {
  SET_COMPANY_ID,
  SET_PERIOD_ID
} from '@/core/services/store/common.module';
import axios from 'axios';

export default {

  name: 'MemberExitConfirmPopup',

  props: ['member_id'],

  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons
  },

  watch: {
    currentCompanyId() {
      
    },
    currentPeriodId() {
      this.item.skv_period_id = this.currentPeriodId;
    }
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
    is_mobile() {
      return is_mobile();
    },
    period_options() {
      return this.periods.map(( item ) => ( { text: item.name, value: item.id }));
    },
  },

  methods: {
    show() {
      this.$refs['edit-form'].show();
    },


    async on_submit() {
      await this.put_member_status();

      this.$refs['saveButton'].stop();

      this.$refs['edit-form'].hide();
    },

    async put_member_status() {
      try {
        const res = await axios.put(`/member/status/${this.member_id}`, { status: 'EXITED', deactivate_at: this.item.deactivate_at, skv_period_id: this.item.skv_period_id});

        if (res.status === 200) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.EXIT_CONFIRMED_MESSAGE'));

          this.$emit('exit_success', this.member_id);
        }
      }
      catch (err) {
        console.error('put_member_status error', err)
      }


    }


  },

  mounted() {

    this.item.skv_period_id = this.currentPeriodId;
    this.item.deactivate_at = dayjs().format('YYYY-MM-DD')
  },

  data() {
    return {
      item: {
        deactivate_at: null,
        skv_period_id: null
      }
    };
  }
};

</script>


<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
